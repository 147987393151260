<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>创新创业大赛 | 决赛圆满告捷，冠军花落谁家？</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>创新创业大赛 | 决赛圆满告捷，冠军花落谁家？</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/3/29</span>
          <span class="time-title">中山青年人才创新创业大赛决赛</span>
          <span class="gold">第三名
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">3月29日，粤港澳大湾区中山青年人才创新创业大赛总决赛，在中山市易创空间创业孵化基地落下帷幕，按企业组和个人组角逐产生冠军、亚军、季军、优胜奖和入围奖等多个奖项。最终，广州海斯波迪生物科技有限公司和压电风暴项目团队分别荣获企业组和个人组的冠军。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhongshan1.jpg" alt=""></div>
        </div>
        <p class="fourth-center">决赛现场</p>
        <p class="fourth-p2">作为2019年“中山人才节”的重要活动之一，大赛旨在激发青年人才的创新创业激情和动力，营造尊重创新、尊重创造的社会氛围，吸引及培育优秀的创业青年在中山创新创业，深度融入粤港澳大湾区建设。市委副书记陈文锋出席活动。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhongshan2.jpg" alt=""></div>
        </div>
        <p class="fourth-center">决赛吸引了众多青年参加</p>
        <p class="fourth-p2">陈文锋现场介绍了中山市委、市政府高度重视开展创新创业工作的重要举措，鼓励创业青年朋友加强学习、增强担当意识和实干意识，积极投身粤港澳大湾区建设，在推动国家进步、促进社会发展中追逐青春梦想，在紧跟发展潮流、引领创业风尚中做时代弄潮儿；希望社会各界支持青年创新创业，为更多青年实现出彩人生搭建广阔舞台。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhongshan3.jpg" alt=""></div>
        </div>
        <p class="fourth-center">市委副书记陈文锋讲话</p>
        <p class="fourth-p2">自今年2月启动以来，大赛共吸引近400名海内外创业青年参加，收到来自22个省（直辖市）及港澳台地区、国外参赛项目138个。与往年相比，本次大赛中众多尖端项目表现优秀、发展势头强劲，港澳地区参赛项目数量增加至7个，并首次吸引美国、日本青年参加。</p>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/zhongshan4.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/zhongshan5.jpg" alt=""></div>
        </div>
        <p class="fourth-center">选手路演环节</p>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/zhongshan6.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/zhongshan7.jpg" alt=""></div>
        </div>
        <p class="fourth-center">评委提问及点评环节</p>
        <p class="fourth-p2">参赛项目所涉领域广泛，充分集聚创新要素，科技含量突出。参赛项目中，中山本土项目占30%，省内周边城市参赛项目占比超过20%，省外地区参赛项目占比近50%，说明广大创业青年看好中山创新创业的发展潜能。参赛团队覆盖综合型博士团队、青春的在校大学生创客、专业领域的创业小白等群体。</p>
        <div class="fourth" style="margin-bottom:14px">
          <div class="fourth-img1"><img src="@/assets/img/zhongshan8.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/zhongshan9.jpg" alt=""></div>
        </div>
        <div class="fourth">
          <div class="fourth-img1"><img src="@/assets/img/zhongshan10.jpg" alt=""></div>
          <div class="fourth-img2"><img src="@/assets/img/zhongshan11.jpg" alt=""></div>
        </div>
        <p class="fourth-center">选手们在10分钟时间里各展所能</p>
        <p class="fourth-p2">本次大赛设置奖金总额达47.5万元，通过以奖代促推动优胜项目“落户”中山。入围决赛项目团队将获得推荐加入中山市青年创业协会、入驻中山市易创空间创业孵化基地、获取创业政策支持和增值服务等“三个优先”系列服务，有效助推优秀创业青年与中山创业青年的融合、与中山创新创业事业的协同发展。</p>
        <div class="firth">
          <div><img src="@/assets/img/zhongshan12.jpg" alt=""></div>
        </div>
        <p class="fourth-center">大合照</p>
        <p class="fourth-p2">大赛由中山市人才工作领导小组主办，共青团中山市委员会、中山市人力资源和社会保障局、中山市青年联合会共同承办，是面向粤港澳大湾区举行的第二年，得到港澳地区青年的大力支持。大赛将为粤港澳大湾区及更广泛的青年创新创业提供更多机遇和更好条件，也让更多的优秀创业项目团队有机会了解中山、走进中山、扎根中山这片热土。</p>
        <div class="firth" style="margin-bottom:14px">
          <div><img src="@/assets/img/zhongshan13.jpg" alt=""></div>
        </div>
        <div class="firth" style="margin-bottom:14px">
          <div><img src="@/assets/img/zhongshan14.jpg" alt=""></div>
        </div>
        <div class="firth">
          <div><img src="@/assets/img/zhongshan15.jpg" alt=""></div>
        </div>
        <p class="fourth-center">冠亚季军获奖者</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="https://mp.weixin.qq.com/s/lMgbFjD4slrkwAT-w-A7mw">https://mp.weixin.qq.com/s/lMgbFjD4slrkwAT-w-A7mw</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newsix"><img src="@/assets/img/jinling1.jpg" alt=""></a>
            <div class="middle">
              <a href="newsix" class="h4">2019(第三届)全球青年创新大会金领奖年度盛典</a>
              <span class="middle-p1">全球青年创新大会</span>
              <span class="middle-p2">金领奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newtwo"><img src="@/assets/img/haibao.jpg" alt=""></a>
            <div class="middle">
              <a href="newtwo" class="h4">第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满收官</a>
              <span class="middle-p1">中国青年创新创业大赛</span>
              <span class="middle-p2">金奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/24 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
    text-align: center;
    margin-top: 16px;
    font-family: PingFang-SC-Bold;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0px;
    color: #666666;
}
p{
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin-top: 41px;
    // margin-left: 70px;
    // padding: 10px 153px 10px 260px;
    padding: 30px;
    overflow: hidden;
    margin: auto;
    width: 1420px;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
          margin-right: 14px;
          float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
            display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
